import React from 'react';
import './style/main.less';
import logo from './images/logo.svg';
import {
  MainLayout,
  Page,
  AuthenticatedRoute,
  TranslationsPage,
  JobsPage,
  UploadedFilesPage,
  GraphQLPage
} from 'lib';
import { UploadDataPage } from 'pages/UploadData';
import { ProductsPage } from 'pages/Products';
const Logo: React.FC = () => (
  <div className="logo">
    <img src={logo} alt="logo" />
  </div>
);

const pages: Page[] = [
  {
    label: 'Products',
    key: 'products',
    routeComponent: AuthenticatedRoute,
    render: () => <ProductsPage />
  },
  {
    label: 'Upload data',
    key: 'upload-data',
    routeComponent: AuthenticatedRoute,
    render: () => <UploadDataPage />
  },
  {
    label: 'Uploaded files',
    key: 'uploaded-files',
    routeComponent: AuthenticatedRoute,
    render: () => <UploadedFilesPage />
  },
  {
    label: 'Translations',
    key: 'translations',
    routeComponent: AuthenticatedRoute,
    render: () => (
      <TranslationsPage
        langs={{
          en: 'English',
          nl: 'Dutch',
          de: 'Deutsch',
          fr: 'French'
        }}
      />
    )
  },
  {
    label: 'Jobs',
    key: 'jobs',
    routeComponent: AuthenticatedRoute,
    render: () => <JobsPage />
  },
  {
    label: 'Console',
    key: 'graphql-console',
    routeComponent: AuthenticatedRoute,
    render: () => <GraphQLPage />
  }
];

export const App: React.FunctionComponent = () => (
  <MainLayout pages={pages} logo={Logo} />
);
