import React from 'react';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Spinner,
  Error,
  EditableTable,
  EditableTableColumn,
  BooleanCellRenderer,
  ImageRendererCreator,
  DataExportImport
} from 'lib';
import { PageHeader, Row, Col } from 'antd';

const allProducts = gql`
  query allProducts {
    allProducts {
      id
      images {
        uri
      }
      internalData {
        popular
      }
      externalData {
        i18n {
          lang(langCode: "en") {
            name
          }
        }
      }
    }
  }
`;
const updateInternalData = gql`
  mutation updateInternalData($id: ID!, $data: InternalDataInput!) {
    updateInternalData(productId: $id, internalData: $data) {
      id
      internalData {
        popular
      }
    }
  }
`;
interface InternalData {
  popular: boolean;
}
interface Product {
  id: string;
  images: { uri: string }[];
  internalData: InternalData;
  externalData: {
    i18n: {
      lang: {
        name: string;
      };
    };
  };
}

interface ProductsTableProps {
  data: [Product];
  saveInternalData: (id: string, data: InternalData) => Promise<void>;
  saving: boolean;
}

declare type ProductType = Product;

const ProductData: React.FC<{
  component: React.ComponentType<ProductsTableProps>;
}> = ({ component: Component }) => {
  const { loading, error, data } = useQuery(allProducts);
  const [saveData, { loading: saving, error: mutationError }] = useMutation(
    updateInternalData
  );
  const saveInternalData = async (id: string, rawData: InternalData) => {
    const data = rawData;
    await saveData({
      variables: {
        id,
        data
      }
    });
  };
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    console.error(error);
    return <Error data={error.message} />;
  }
  if (mutationError) {
    console.error(mutationError);
    return <Error data={mutationError.message} />;
  }
  return (
    <Component
      data={data.allProducts}
      saveInternalData={saveInternalData}
      saving={saving}
    />
  );
};

const ProductsTable: React.FC<ProductsTableProps> = ({
  data,
  saveInternalData,
  saving
}) => {
  const saveData = async (id: string, data: Product) => {
    await saveInternalData(id, data.internalData);
  };
  const idDataIndex = 'id';
  const columns: EditableTableColumn<ProductType>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      editable: false,
      width: '10%',
      searchable: true
    },
    {
      title: 'Image',
      dataIndex: ['images', 0, 'uri'],
      width: '15%',
      render: ImageRendererCreator(50),
      editable: false,
      searchable: false
    },
    {
      title: 'Name',
      dataIndex: ['externalData', 'i18n', 'lang', 'name'],
      editable: false,
      width: '20%',
      searchable: true
    },
    {
      title: 'Popular',
      dataIndex: ['internalData', 'popular'],
      searchable: false,
      editable: true,
      sorter: (a, b) => {
        const popA = a.internalData.popular;
        const popB = b.internalData.popular;
        if (popA === popB) {
          return 0;
        }
        if (popA) {
          return -1;
        }
        return 1;
      },
      width: '10%',
      render: BooleanCellRenderer
    }
  ];
  const actionsColumn = {
    title: 'Actions',
    dataIndex: 'actions',
    width: '10%'
  };
  return (
    <EditableTable
      actionsColumn={actionsColumn}
      columns={columns}
      idDataIndex={idDataIndex}
      data={data}
      saveData={saveData}
      saving={saving}
    />
  );
};

export const ProductsPage: React.FunctionComponent = () => (
  <>
    <PageHeader
      title="Products"
      subTitle="Show and edit products"
      backIcon={false}
    ></PageHeader>
    <div className="header-padded">
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <DataExportImport
            className="com.innotactsoftware.backend.api.model.Product"
            outputType="xlsx"
          />
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <ProductData component={ProductsTable}></ProductData>
        </Col>
      </Row>
    </div>
  </>
);
