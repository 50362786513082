import React from 'react';
import { Col, PageHeader, Row } from 'antd';
import { DataUpload } from 'lib';

export const UploadDataPage: React.FunctionComponent = () => (
  <>
    <PageHeader
      title="Upload data"
      subTitle="Upload new Products and Filter tags"
      backIcon={false}
    ></PageHeader>
    <div className="header-padded">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <DataUpload
            fileName="wallpapers.shopify.xlsx"
            label="Wallpapers excel"
          />
        </Col>
        <Col span={12}>
          <DataUpload
            fileName="filtertags.shopify.xlsx"
            label="Filtertags excel"
          />
        </Col>
        <Col span={12}>
          <DataUpload
            fileName="collections.shopify.xlsx"
            label="Collections excel"
          />
        </Col>
      </Row>
    </div>
  </>
);
